body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "GT Walsheim";
  src: url(/static/media/GT-Walsheim-Pro-Regular.a1545b5c.otf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim";
  src: url(/static/media/GT-Walsheim-Pro-Bold.ed4a2e6b.otf);
  font-weight: bold;
  font-style: normal;
}

body * {
  font-family: "GT Walsheim" !important;
}

.app {
  overflow: auto;
  min-height: 100vh;
}

.hal-ui-container {
  max-width: 1000px;
  margin: 0px auto 50px auto;
}

