@font-face {
  font-family: "GT Walsheim";
  src: url("./fonts/GT-Walsheim-Pro-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim";
  src: url("./fonts/GT-Walsheim-Pro-Bold.otf");
  font-weight: bold;
  font-style: normal;
}

body * {
  font-family: "GT Walsheim" !important;
}

.app {
  overflow: auto;
  min-height: 100vh;
}

.hal-ui-container {
  max-width: 1000px;
  margin: 0px auto 50px auto;
}
